import React, {useState, useContext, useEffect} from 'react';
import styles from "../rate-building.module.scss";
import Nav from "../../../components/nav/nav";
import RatingSteps from "../../../components/rating-steps/rating-steps";
import Button from "../../../components/button/button";
import Select from "../../../components/select/select";
import BuildingContext from "../rate-building-context";
import NavBar from "../../../components/nav-bar/nav-bar";
import firebase from "firebase/app";
import { uniqBy } from 'lodash';

const Location = function() {
  const db = firebase.firestore();
  const inspector = useContext(BuildingContext);
  let [disableBtn, setDisableBtn] = useState(true);
  let [inspectorsLoaded, setInspectorsLoaded] = useState(false);
  let [countiesLoaded, setCountiesLoaded] = useState(false);
  let [displayingCountyOptions, setDisplayingCountyOptions] = useState(false);

  function getData() {
    let defaultArray = [{ val: 'Please choose a location', disabled: true }];
    let dataArray = [];

    // fetch locations from firebase
    if(typeof inspector.locations === 'undefined') {
      db.collection('locations')
        .get()
        .then((snapshot) => {
          dataArray = snapshot.docs.map(doc => (
              {
                id: doc.data().id,
                val: `${doc.data().city}, ${doc.data().state}`,
                disabled: false
              }
            )
          );
          dataArray.sort((a,b) => a.val > b.val ? 1 : -1);
          inspector.locations = defaultArray.concat(dataArray);
          setInspectorsLoaded(true);
        });
    }

    // don't fetch just display
    if(typeof inspector.locations !== 'undefined') {
      setInspectorsLoaded(true);
    }
  }

  function getSelectedOption(childData) {
    if(typeof childData.val !== 'undefined'){
      inspector.location = childData.val;
      inspector.id = childData.id;
    }

    if(inspector.location !== 'Please choose a location' && typeof inspector.location !== 'undefined') {
      setDisableBtn(false);
    }
  }

  function renderSelect() {
    if(inspectorsLoaded && !displayingCountyOptions){
      return (
        <Select selectedOption={getSelectedOption} selected={inspector.location} options={inspector.locations}/>
      )
    }
    else return false;
  }

  function getCountyOptions() {
    let defaultArray = [{ val: 'Please choose a county', disabled: true }];
    let dataArray = [];

    // fetch counties from firebase
    if(typeof inspector.counties === 'undefined') {
      db.collection('locations')
        .get()
        .then((snapshot) => {
          dataArray = snapshot.docs.map(doc => (
              {
                id: doc.data().id,
                val: doc.data().county,
                disabled: false
              }
            )
          );
          dataArray.sort((a,b) => a.val > b.val ? 1 : -1);
          inspector.counties = defaultArray.concat(uniqBy(dataArray, 'val'));
          setCountiesLoaded(true);
        });
    }

    // don't fetch just display
    if(typeof inspector.counties !== 'undefined') {
      setInspectorsLoaded(true);
    }
  }

  function renderCountySelect() {
    if(countiesLoaded && displayingCountyOptions){
      return (
        <Select selectedOption={getSelectedCountyOption} selected={inspector.county} options={inspector.counties}/>
      )
    }
    else return false;
  }

  function getSelectedCountyOption(childData) {
    if(typeof childData.val !== 'undefined'){
      inspector.county = childData.val;
      inspector.id = childData.id;
    }

    if(inspector.county !== 'Please choose a county' && typeof inspector.county !== 'undefined') {
      setDisableBtn(false);
    }
  }

  function displayCountyOptions(e) {
    e.preventDefault();

    if(displayingCountyOptions) {
      setDisplayingCountyOptions(false);
    }
    else {
      setDisplayingCountyOptions(true);
    }
  }

  useEffect(() => {
    getData();
    getCountyOptions();
  });

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Building Department Rating</h1>
          <RatingSteps type='building'/>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.centeredContent}>
            <img src="/images/content/location.svg" alt=""/>
            {renderSelect()}

            {renderCountySelect()}

            <Button disabled={disableBtn} label="Next" kind="primary" alignment="center" to="/rate-building/rating"/>

            {!displayingCountyOptions &&
            <small>
              {/* eslint-disable-next-line */}
              Don't see your city? <a href="#" onClick={(e) => {displayCountyOptions(e)}}>Click here to select from a county</a>.
            </small>
            }

            {displayingCountyOptions &&
            <small>
              {/* eslint-disable-next-line */}
              Select from a city <a href="#" onClick={(e) => {displayCountyOptions(e)}}>click here</a>.
            </small>
            }
          </div>
        </div>
      </section>
    </main>
  )
};

export default Location;
