import React, {useState, useRef} from 'react';
import styles from './tool-tip.module.scss';

const ToolTip = (props) => {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const offset = 7;

  const tipPositionStyle = {
    top: - height,
  };

  function showTip() {
    setHeight(ref.current.clientHeight + offset);
  }

  function hideTip() {
    setHeight(0);
  }

  return(
    <div className={styles.toolTip} onMouseEnter={showTip} onMouseLeave={hideTip}>
      <div className={styles.container}>
        <i className={styles.indicator}>?</i>
        <p className={styles.tip} ref={ref} style={tipPositionStyle}>{props.tip}</p>
      </div>
    </div>
  )
};

export default ToolTip;
