import React, {useState, useEffect} from 'react';
import styles from './_num-rating.module.scss';

const NumRating = (props) => {
  const totalInputs = 5;
  const [radioSelected, setRadioSelected] = useState(null);
  let [rating, setRating] = useState(0);

  const Input = ({ selected = false, onClick = f => f }) => (
    <input
      type="radio"
      onClick={onClick}
      defaultChecked={selected ? 'checked' : ''}
      className={styles.numInput}
    />
  );

  function dispatchRating(val) {
    if(props.getRating) {
      props.getRating(val);
    }
  }

  useEffect(() => {
    dispatchRating(rating);
  }, [rating]); //eslint-disable-line

  return (
    <div className={styles.numRating}>
      {[...Array(totalInputs)].map((n, i) => (
        <div className={styles.numRatingContainer} key={i}>
          <label className={styles.inputLabel}>{i + 1}</label>
          <Input
            key={i}
            selected={i === radioSelected}
            onClick={() => {
              setRadioSelected(i);
              setRating(i+1);
              }
            }
          />
        </div>
      ))}
    </div>
  )
};

export default NumRating;
