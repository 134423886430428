import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './_rating-steps.module.scss';

const RatingSteps = (props) => {
  function whatToInspect () {
    return props.type === 'inspector' ? 'Inspector' : 'Building Dept.';
  }

  return (
    <nav className={styles.ratingSteps}>
      <ul className={styles.steps}>
        <NavLink to={{pathname: `/rate-${props.type}/location`}} activeClassName={styles.active}>
          {props.type === 'inspector' &&
            <li>City</li>
          }
          {props.type !== 'inspector' &&
          <li>City or County</li>
          }
        </NavLink>

        {props.type === 'inspector' &&
        <NavLink to={{pathname: `/rate-${props.type}/inspector`}} activeClassName={styles.active}>
          <li>
            {whatToInspect()}
          </li>
        </NavLink>
        }

        <NavLink to={{pathname: `/rate-${props.type}/rating`}} activeClassName={styles.active}>
          <li>
            Rating
          </li>
        </NavLink>

        <NavLink to={{pathname: `/rate-${props.type}/preview`}} activeClassName={styles.active}>
          <li>
            Preview
          </li>
        </NavLink>
      </ul>
    </nav>
  )
};

export default RatingSteps;
