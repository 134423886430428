import React, {useState, useEffect} from 'react';
import styles from './_star-rating.module.scss';

const StarRating = (props) => {
  const totalStars = 5;
  const [starsSelected, selectStar] = useState(props.start ? props.start : 0);

  const Star = ({ selected = false, onClick = f => f }) => (
    <i className={selected ? styles.star + ' ' + styles.selected : styles.star} onClick={onClick} />
  );

  function dispatch(val) {
    if (props.getRating) {
      props.getRating(val);
    }
  }

  useEffect(() => {
    dispatch(starsSelected);
  }, [starsSelected]); // eslint-disable-line

  return (
      <ul className={props.disabled ? styles.starRating + ' ' + styles.disabled: styles.starRating}>
        <li>
          {[...Array(totalStars)].map((n, i) => (
            <Star
              key={i}
              selected={i < starsSelected}
              onClick={() => selectStar(i + 1)}
            />
          ))}
        </li>
      </ul>
  )
};

export default StarRating;
