import React from 'react';
import Nav from '../../components/nav/nav';
import NavBar from '../../components/nav-bar/nav-bar';
import FileUpload from '../../components/file-upload/file-upload';

import styles from '../rate-inspector/rate-inspector.module.scss';
// import adminStyle from './admin.module.scss';

const Upload = () => {
  return (
    <main className={styles.splitView}>
      <Nav />
      <NavBar />

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Admin</h1>
        </header>

        <div className={styles.contentContainer}>
          <h2>Upload Locations</h2>

          <FileUpload dataType='location' />

          <h2>Upload Inspectors</h2>

        </div>
      </section>
    </main>
  );

};

export default Upload;
