import React from 'react';
import styles from "../rate-inspector/rate-inspector.module.scss";
import Nav from "../../components/nav/nav";
import completedStyles from './completed.module.scss';
import Button from "../../components/button/button";
import NavBar from "../../components/nav-bar/nav-bar";

const Completed = () => {
  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.centeredContent}>

            <div className={completedStyles.completed}>
              <img src="/images/content/completed.svg" alt=""/>
              <h1>All set, thanks for submitting your review!</h1>
              <Button label="View All Reviews" to="/reviews"> </Button>
            </div>

          </div>
        </div>
      </section>

    </main>
  )
};

export default Completed;
