import React, { useState, useContext } from 'react';
import styles from "../rate-inspector.module.scss";
import Nav from "../../../components/nav/nav";
import RatingSteps from "../../../components/rating-steps/rating-steps";
import ratingStyles from './rating.module.scss';
import Button from "../../../components/button/button";
import StarRating from "../../../components/star-rating/star-rating";
import InspectorContext from "../rate-inspector-context";
import NavBar from "../../../components/nav-bar/nav-bar";
import NumRating from '../../../components/num-rating/num-rating';
import ToolTip from '../../../components/tool-tip/tool-tip';

const Rating = (props) => {
  const inspector = useContext(InspectorContext);

  if(typeof props.location.state !== 'undefined') {
    inspector.name = props.location.state.inspector.name;
    inspector.id = props.location.state.inspector.id;
    inspector.location = props.location.state.inspector.location.city + ' ' + props.location.state.inspector.location.stateAbbreviation;
  }

  let [quote, setQuote] = useState(inspector.quote);
  let [headline, setHeadline] = useState(inspector.headline);
  const [disableBtn, setDisableBtn] = useState(true);

  function handleHeadlineInput(event) {
    inspector.headline = event.target.value;
    setHeadline(inspector.headline);
    validateForm()
  }

  function handleQuoteInput(event) {
    inspector.quote = event.target.value;
    setQuote(inspector.quote);
    validateForm()
  }

  function getStarRating(childData) {
    inspector.rating = childData;
    validateForm()
  }

  function getPunctualRating(childData) {
    inspector.punctualRating = childData;
  }

  function getKnowledgeableRating(childData) {
    inspector.knowledgeableRating = childData;
  }

  function getDemeanorRating(childData) {
    inspector.demeanorRating = childData;
  }

  function redirect() {
    if (inspector.name == null || inspector.location == null || inspector.name === 'Please select an inspector'|| inspector.location === 'Please choose a location') {
      window.location = "/rate-inspector/location"
    }
  }

  function validateForm() {
    if(typeof inspector.headline !== 'undefined' && typeof inspector.quote !== 'undefined' && inspector.quote !== '' && inspector.rating !== undefined) {
      setDisableBtn(false);
    }
    if(inspector.headline === '' || inspector.quote === '') {
      setDisableBtn(true);
    }
  }

  redirect();

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Inspector Rating</h1>
          <RatingSteps type='inspector'/>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <fieldset className={ratingStyles.rating}>
              <legend>Create Review</legend>
              <p>You have selected to create a review for <strong>{inspector.name}</strong> a {inspector.position} from <strong>{inspector.location}</strong>.
                Please complete the form below to continue.
              </p>

              <form>
                <div className={ratingStyles.formCol}>
                  <div className={ratingStyles.formRow}>
                    <em>Overall Rating</em>
                    <StarRating getRating={getStarRating} start={inspector.rating}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Review Headline <ToolTip tip="This is an informative title for reviews e.g., Great service, great price! or Timely & professional"/></em>
                    <input type="text" value={headline} onChange={handleHeadlineInput}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Review</em>
                    <textarea value={quote} onChange={handleQuoteInput} rows="8"/>
                  </div>
                </div>

                <div className={ratingStyles.formCol}>
                  <div className={ratingStyles.formRow}>
                    <em>Punctual <ToolTip tip="Completes project/work at the agreed upon or proper time."/></em>
                    <NumRating start={inspector.punctualRating} getRating={getPunctualRating}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Knowledgeable <ToolTip tip="Knows the codes and construction."/></em>
                    <NumRating start={inspector.knowledgeableRating} getRating={getKnowledgeableRating}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Demeanor <ToolTip tip="Great to work with or God complex/ power mongrel."/></em>
                    <NumRating start={inspector.demeanorRating} getRating={getDemeanorRating}/>
                  </div>
                </div>
              </form>

              <Button disabled={disableBtn} kind="primary" label="Preview" to="/rate-inspector/preview"/>
            </fieldset>
          </div>
        </div>
      </section>

    </main>
  )
};

export default Rating;
