import React, {useEffect, useState} from 'react';
import { Link } from "react-router-dom";
import styles from "../rate-inspector/rate-inspector.module.scss";
import reviewStyles from "./reviews.module.scss";
import Nav from "../../components/nav/nav";
import NavBar from "../../components/nav-bar/nav-bar";
import ReviewToolBar from "../../components/review-toolbar/review-toolbar";
import StarRatings from '../../../node_modules/react-star-ratings';
import firebase from "firebase";

const Reviews = () => {
  const db = firebase.firestore();

  // inspector
  let [reviewsLoaded, setReviewsLoaded] = useState(false);
  let [ratingsLoaded, setRatingsLoaded] = useState(false);
  const [reviews, setReviews] = useState([]);
  const [ratings, setRatings] = useState([]);
  let [noResults, setNoResults] = useState(false);

  // building dept
  let [buildingDeptReviewsLoaded, setBuildingDeptReviewsLoaded] = useState(false);
  let [buildingDeptRatingsLoaded, setBuildingDeptRatingsLoaded] = useState(false);
  const [buildingDeptReviews, setBuildingDeptReviews] = useState([]);
  const [buildingDeptRatings, setBuildingDeptRatings] = useState([]);

  function getLocationFromInput(childData){
    if(childData === '') {
      getInspectors()
    }
    else {
      getInspectorsByCity(childData);
    }
  }

  function getInspectors(){
    db.collection('inspectors')
      .get()
      .then((snapshot) => {
        let dataArray = [];

        snapshot.docs.forEach(doc => {
          dataArray.push(doc.data());
        });

        setNoResults(false);
        setReviews(dataArray);
        setReviewsLoaded(true);
      })
  }

  function getInspectorsByCity(city){
    db.collection('inspectors')
      .where("location.city", "==", city)
      .get()
      .then((snapshot) => {
        let dataArray = [];

        if (snapshot.empty) {
          setNoResults(true);
          return;
        }

        snapshot.docs.forEach(doc => {
          dataArray.push(doc.data());
        });

        setNoResults(false);
        setReviews(dataArray);
      })
  }

  function getRatings() {
    db.collection('ratings')
      .orderBy('rating', 'desc')
      .get()
      .then((snapshot) => {
        let ratingArray = [];

        snapshot.docs.forEach(doc => {
          ratingArray.push(doc.data());
        });

        setRatings(ratingArray);
        setRatingsLoaded(true);
      })
  }

  function calculateAverageRating(inspectorId) {
    let fiveStarRatings = ratings.filter(function(arr){return arr.rating === 5 && arr.inspectorId === inspectorId});
    let fourStarRatings = ratings.filter(function(arr){return arr.rating === 4 && arr.inspectorId === inspectorId});
    let threeStarRatings = ratings.filter(function(arr){return arr.rating === 3 && arr.inspectorId === inspectorId});
    let twoStarRatings = ratings.filter(function(arr){return arr.rating === 2 && arr.inspectorId === inspectorId});
    let oneStarRatings = ratings.filter(function(arr){return arr.rating === 1 && arr.inspectorId === inspectorId});

    return (5*fiveStarRatings.length + 4*fourStarRatings.length + 3*threeStarRatings.length + 2*twoStarRatings.length + 1*oneStarRatings.length) / (fiveStarRatings.length + fourStarRatings.length + threeStarRatings.length + twoStarRatings.length + oneStarRatings.length);
  }

  function calculateAverageBuildingRating(locationId) {
    let fiveStarRatings = buildingDeptRatings.filter(function(arr){return arr.rating === 5 && arr.locationId === locationId});
    let fourStarRatings = buildingDeptRatings.filter(function(arr){return arr.rating === 4 && arr.locationId === locationId});
    let threeStarRatings = buildingDeptRatings.filter(function(arr){return arr.rating === 3 && arr.locationId === locationId});
    let twoStarRatings = buildingDeptRatings.filter(function(arr){return arr.rating === 2 && arr.locationId === locationId});
    let oneStarRatings = buildingDeptRatings.filter(function(arr){return arr.rating === 1 && arr.locationId === locationId});

    return (5*fiveStarRatings.length + 4*fourStarRatings.length + 3*threeStarRatings.length + 2*twoStarRatings.length + 1*oneStarRatings.length) / (fiveStarRatings.length + fourStarRatings.length + threeStarRatings.length + twoStarRatings.length + oneStarRatings.length);
  }

  function renderReviewContent() {
    if(reviewsLoaded && ratingsLoaded) {
      return (
        <>
          {reviews.map((review, index) => {
            if(!isNaN(calculateAverageRating(review.id)) && calculateAverageRating(review.id) > 0){
              return (
                <Link key={index} to={{pathname: `/inspector/${review.id.toLowerCase()}`, state: {inspector: review}}}>
                  <li className={reviewStyles.list} key={review.name}>
                    <img src="/images/content/inspector-coaster.svg" alt=""/>
                    <h3>{review.name}</h3>
                    <p>{review.position}</p>
                    <small>Average Rating: {calculateAverageRating(review.id).toFixed(2)}</small>
                    <StarRatings
                      rating={calculateAverageRating(review.id)}
                      starRatedColor="#FFCE02"
                      numberOfStars={5}
                      starDimension="22px"
                      starSpacing="2px"
                      name='rating'
                    />
                  </li>
                </Link>
              )
            }
            else return false;
          })
          }
        </>
      )
    }
  }

  function getBuildingDeptRatings() {
    db.collection('buildingDeptRatings')
      .orderBy('rating', 'desc')
      .get()
      .then((snapshot) => {
        let ratingArray = [];

        snapshot.docs.forEach(doc => {
          ratingArray.push(doc.data());
        });

        setBuildingDeptRatings(ratingArray);
        setBuildingDeptRatingsLoaded(true);
      })
  }

  function getBuildingDepartments(){
    db.collection('locations')
      .get()
      .then((snapshot) => {
        let dataArray = [];

        snapshot.docs.forEach(doc => {
          dataArray.push(doc.data());
        });

        // setNoResults(false);
        setBuildingDeptReviews(dataArray);
        setBuildingDeptReviewsLoaded(true);
      })
  }

  function renderBuildingDeptContent() {
    if(buildingDeptReviewsLoaded && buildingDeptRatingsLoaded) {
      return (
        <>
          {buildingDeptReviews.map((bldgDept, index) => {
            if(!isNaN(calculateAverageBuildingRating(bldgDept.id)) && calculateAverageBuildingRating(bldgDept.id) > 0) {
              return (
                <Link to={{pathname: `/bldg-dept/${bldgDept.id.toLowerCase()}`, state: {buildingDepartment: bldgDept}}}>
                  <li className={reviewStyles.list} key={index}>
                    <img src="/images/content/building-coaster.svg" alt=""/>
                    <h3>{bldgDept.city}</h3>
                    <small>Average Rating: {calculateAverageBuildingRating(bldgDept.id).toFixed(2)}</small>
                    <StarRatings
                      rating={calculateAverageBuildingRating(bldgDept.id)}
                      starRatedColor="#FFCE02"
                      numberOfStars={5}
                      starDimension="22px"
                      starSpacing="2px"
                      name='rating'
                    />
                  </li>
                </Link>
              )}
            else return false;
          })
          }
        </>
      )
    }
  }

  useEffect(() => {
    getInspectors();
    getRatings();

    getBuildingDepartments();
    getBuildingDeptRatings()
  });

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Reviews</h1>
          <ReviewToolBar records={reviews.length} location={getLocationFromInput} locationResult={noResults}/>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={reviewStyles.reviews}>
              <ul className={reviewStyles.listContainer}>
                {renderReviewContent()}
                {renderBuildingDeptContent()}
                <li className={reviewStyles.list + ' ' + reviewStyles.advertisement}>
                  <img src="/images/content/ad.svg" alt=""/>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
};

export default Reviews;
