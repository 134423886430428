import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './_nav.module.scss';

const Nav = () => {
  return (
    <nav className={styles.nav}>
      <header>
        <NavLink to="/">
          <img src="/images/branding/rmi-logo.svg" alt="Rate my inspector logo"/>
        </NavLink>

        <small>Reliable, simple & modern rating system!</small>
      </header>

      <ul>
        <NavLink to="/rate-inspector/location" activeClassName={styles.active}>
          <li>
            <img src="/images/content/clipboards.svg" alt=""/>
            <span>Rate Inspector</span>
          </li>
        </NavLink>

        <NavLink to="/rate-building/location" activeClassName={styles.active}>
          <li>
            <img src="/images/content/building.svg" alt=""/>
            <span>Rate Building Department</span>
          </li>
        </NavLink>

        <NavLink to="/reviews" activeClassName={styles.active}>
          <li>
            <img src="/images/content/reviews.svg" alt=""/>
            <span>Reviews</span>
          </li>
        </NavLink>
      </ul>

      <footer>
        <small>&copy; 2019 All Rights Reserved RMI</small>
      </footer>
    </nav>
  )
};

export default Nav;
