import React, { useState, useContext } from 'react';
import styles from "../rate-building.module.scss";
import Nav from "../../../components/nav/nav";
import RatingSteps from "../../../components/rating-steps/rating-steps";
import ratingStyles from './rating.module.scss';
import Button from "../../../components/button/button";
import StarRating from "../../../components/star-rating/star-rating";
import BuildingContext from "../rate-building-context";
import NavBar from "../../../components/nav-bar/nav-bar";
import ToolTip from '../../../components/tool-tip/tool-tip';
import NumRating from '../../../components/num-rating/num-rating';

const Rating = () => {
  const inspector = useContext(BuildingContext);
  let [quote, setQuote] = useState(inspector.quote);
  let [headline, setHeadline] = useState(inspector.headline);
  const [disableBtn, setDisableBtn] = useState(true);

  function handleHeadlineInput(event) {
    inspector.headline = event.target.value;
    setHeadline(inspector.headline);
    validateForm()
  }

  function handleQuoteInput(event) {
    inspector.quote = event.target.value;
    setQuote(inspector.quote);
    validateForm()
  }

  function getStarRating(childData) {
    inspector.rating = childData;
    validateForm()
  }

  function getWebSiteRating(childData) {
    inspector.websiteRating = childData;
  }

  function getPlanCheckRating(childData) {
    inspector.planCheckRating = childData;
  }

  function getPermitProcessRating(childData) {
    inspector.permitProcessRating = childData;
  }

  function getFeeRating(childData) {
    inspector.feeRating = childData;
  }

  function getStaffRating(childData) {
    inspector.staffRating = childData;
  }

  function redirect() {
    if ((inspector.location == null && inspector.county) == null || inspector.location === 'Please select a department') {
      window.location = "/rate-building/location"
    }
  }

  function validateForm() {
    if(typeof inspector.headline !== 'undefined' && typeof inspector.quote !== 'undefined' && inspector.quote !== '' && inspector.rating !== undefined) {
      setDisableBtn(false);
    }
    if(inspector.headline === '' || inspector.quote === '') {
      setDisableBtn(true);
    }
  }

  redirect();

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Building Department Rating</h1>
          <RatingSteps type='building'/>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <fieldset className={ratingStyles.rating}>
              <legend>Create Review</legend>
              <p>You have selected to create a review for <strong>{inspector.location} {inspector.county}</strong>.
                Please complete the form below to continue.
              </p>

              <form>
                <div className={ratingStyles.formCol}>
                  <div className={ratingStyles.formRow}>
                    <em>Overall Rating</em>
                    <StarRating getRating={getStarRating} start={inspector.rating}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Review Headline <ToolTip tip="This is an informative title for reviews e.g., Great service, great price! or Timely & professional"/></em>
                    <input type="text" value={headline} onChange={handleHeadlineInput}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Review</em>
                    <textarea value={quote} onChange={handleQuoteInput} rows="8"/>
                  </div>
                </div>

                <div className={ratingStyles.formCol}>
                  <div className={ratingStyles.formRow}>
                    <em>Website <ToolTip tip="On a scale from 1-5 how was the website? 1 being poor 5 being excellent."/></em>
                    <NumRating start={inspector.websiteRating} getRating={getWebSiteRating}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Plan check <ToolTip tip="On a scale from 1-5 how was the planning? 1 being poor 5 being excellent."/></em>
                    <NumRating start={inspector.planCheckRating} getRating={getPlanCheckRating}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Permit process <ToolTip tip="On a scale from 1-5 how was the permit process? 1 being very difficult 5 being easy."/></em>
                    <NumRating start={inspector.permitProcessRating} getRating={getPermitProcessRating}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Fees <ToolTip tip="On a scale from 1-5 how was the fees? 1 being over the top expensive 5 being Inexpensive."/></em>
                    <NumRating start={inspector.feeRating} getRating={getFeeRating}/>
                  </div>

                  <div className={ratingStyles.formRow}>
                    <em>Staff <ToolTip tip="On a scale from 1-5 how was the staff? 1 being poor 5 being excellent."/></em>
                    <NumRating start={inspector.staffRating} getRating={getStaffRating}/>
                  </div>
                </div>
              </form>

              <Button disabled={disableBtn} kind="primary" label="Preview" to="/rate-building/preview"/>
            </fieldset>
          </div>
        </div>
      </section>

    </main>
  )
};

export default Rating;
