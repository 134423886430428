// import React from 'react';

const FirebaseConfig = {
  apiKey: "AIzaSyCHyFSbYgzl5mIMovRwGqnRjGpj8xBYEy4",
  authDomain: "rmi-site.firebaseapp.com",
  databaseURL: "https://rmi-site.firebaseio.com",
  projectId: "rmi-site",
  storageBucket: "rmi-site.appspot.com",
  messagingSenderId: "299627226879",
  appId: "1:299627226879:web:dfb68b11e265d1fced4eca",
  measurementId: "G-ZPWDVWP5HC"
};

export default FirebaseConfig;
