import React, {useState} from "react";
import styles from "./_nav-bar.module.scss";
import {NavLink} from "react-router-dom";
import OffCanvas from "../off-canvas/off-canvas";

const NavBar = () => {
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  function displayOffCanvas() {
    setShowOffCanvas(true);
  }

  function dismissOffCanvas() {
    setShowOffCanvas(false);
  }

  return (
    <nav className={styles.navBar}>
      <img onClick={displayOffCanvas} className={styles.menu} src="/images/ui/hamburger.svg" alt=""/>

      <NavLink to="/">
        <img className={styles.branding} src="/images/branding/rmi-icon.svg" alt=""/>
      </NavLink>

      <OffCanvas show={showOffCanvas} handleClose={dismissOffCanvas}/>
    </nav>
  )
};

export default NavBar;
