import React, {useEffect, useState, useContext} from 'react';
import styles from "../rate-inspector.module.scss";
import RatingSteps from "../../../components/rating-steps/rating-steps";
import Button from "../../../components/button/button";
import Select from "../../../components/select/select";
import InspectorContext from "../rate-inspector-context";
import Nav from "../../../components/nav/nav";
import NavBar from "../../../components/nav-bar/nav-bar";
import firebase from "firebase/app";

const Inspector = () => {
  const db = firebase.firestore();
  const inspector = useContext(InspectorContext);
  const [disableBtn, setDisableBtn] = useState(true);
  let [inspectorsLoaded, setInspectorsLoaded] = useState(false);

  function redirect() {
    if (inspector.location == null || inspector.location === 'Please choose a location') {
      window.location = "/rate-inspector/location"
    }
  }

  redirect();

  function getInspectorByLocation() {
    let defaultArray = [{ val: 'Please choose inspector', disabled: true }];
    let dataArray = [];

    db.collection("inspectors")
      .where("location.city", "==", inspector.location.split(',')[0])
      .get()
      .then((snapshot) => {
        if (snapshot.docs.length > 0) {
          dataArray = snapshot.docs.map(doc => (
            {
              id: doc.data().id,
              val: doc.data().name,
              disabled: false,
              location: doc.data().location,
              position: doc.data().position
            }
          ));
          inspector.inspectors = defaultArray.concat(dataArray);
          setInspectorsLoaded(true);
        }
        else {
          setDisableBtn(true);
        }
      })
  }

  function renderSelect() {
    if(inspectorsLoaded){
      return (
        <Select selectedOption={getSelectedOption} selected={inspector.name} options={inspector.inspectors}/>
      )
    }
    else {
      return (
        <p className={styles.noResults}><strong>Sorry</strong> no inspectors found in the <strong>{inspector.location} area</strong>. Try a choosing a different <a
          href="/rate-inspector/location">location</a></p>
      )
    }
  }

  function getSelectedOption(childData) {
    if(typeof childData.val !== 'undefined'){
      inspector.name = childData.val;
      inspector.position = childData.position;
      inspector.id = childData.id;
    }

    if(inspector.name !== 'Please select an inspector' && typeof inspector.name !== 'undefined') {
      setDisableBtn(false);
    }
  }

  useEffect(() => {
    getInspectorByLocation();
  });

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Inspector Rating</h1>
          <RatingSteps type='inspector'/>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.centeredContent}>
            <img src="/images/content/inspector.svg" alt=""/>

            {renderSelect()}

            <Button disabled={disableBtn} label="Next" kind="primary" alignment="center" to="/rate-inspector/rating"/>
          </div>
        </div>
      </section>
    </main>
  )
};

export default Inspector;
