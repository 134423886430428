import React, {useContext} from 'react';
import firebase from "firebase";

import previewStyles from './preview.module.scss';
import BuildingContext from "../rate-building-context";
import Nav from "../../../components/nav/nav";
import RatingSteps from "../../../components/rating-steps/rating-steps";
import StarRating from "../../../components/star-rating/star-rating";
import buttonStyles from '../../../components/button/_button.module.scss';
import NavBar from "../../../components/nav-bar/nav-bar";

import styles from "../rate-building.module.scss";

const Preview = () => {
  const inspector = useContext(BuildingContext);
  const db = firebase.firestore();

  function redirect() {
    if ((inspector.location == null && inspector.county == null) || inspector.headline == null || inspector.quote == null) {
      window.location = "/rate-building/location";
    }
  }

  redirect();

  function handleSubmit(event) {
    event.preventDefault();

    db.collection('buildingDeptRatings').add({
      locationId: inspector.id,
      blurb: inspector.quote,
      headline: inspector.headline,
      rating: inspector.rating,
      websiteRating: inspector.websiteRating,
      planCheckRating: inspector.planCheckRating,
      permitProcessRating: inspector.permitProcessRating,
      feeRating: inspector.feeRating,
      staffRating: inspector.staffRating
    })
      .then(function() {
        window.location = "/completed"
      })
      .catch(function(error) {
        console.error("Error adding inspector rating: ", error);
      });
  }

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Building Department Rating</h1>
          <RatingSteps type='building'/>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.centeredContent}>

            <div className={previewStyles.preview}>
              <div className={previewStyles.profile}>
                <div className={previewStyles.avatar}>
                  <img src="/images/content/building-2.svg" alt=""/>
                </div>
                <div>
                  <div className={previewStyles.info}>
                    <span>{inspector.location}</span>
                    <StarRating start={inspector.rating} disabled={true}/>
                  </div>
                  <div className={previewStyles.additionalInfo}>
                    <p>Website: {inspector.websiteRating}</p>
                    <p>Planning: {inspector.planCheckRating}</p>
                    <p>Permit Process: {inspector.permitProcessRating}</p>
                    <p>Fees: {inspector.feeRating}</p>
                    <p>Staff: {inspector.staffRating}</p>
                  </div>
                </div>
              </div>

              <div className={previewStyles.review}>
                <h4>{inspector.headline}</h4>
                <p>{inspector.quote}</p>
                <button className={buttonStyles.primary} onClick={handleSubmit}>Submit Review</button>
              </div>
            </div>

          </div>
        </div>
      </section>

    </main>
  )
};

export default Preview;
