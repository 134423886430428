import React, {useState, useEffect} from 'react';
import styles from './_select.module.scss';

const Select = (props) => {
  const [optionSelected, setOptionSelected] = useState(props.selected ? props.selected : props.options[0].val);

  useEffect(() => {
    dispatch(optionSelected);
  }, [optionSelected]); // eslint-disable-line

  const options = props.options.map((data, index) => {
    return (
      <option disabled={data.disabled ? true : ''} value={data.val} key={index}>{data.val}</option>
    )
  });

  function handleChange(event) {
    if(window.scrollY > 0) {
      window.scrollTo(0,0);
    }
    setOptionSelected(props.options[event.target.selectedIndex]);
  }

  function dispatch(val) {
    props.selectedOption(val);
  }

  return (
    <select className={styles.select} onChange={handleChange} defaultValue={optionSelected}>
      {options}
    </select>
  )
};

export default Select;
