import React from 'react';
import { Route } from 'react-router-dom';
import Landing from './pages/landing/landing';
import InspectorLocation from './pages/rate-inspector/location/location';
import InspectorSelection from './pages/rate-inspector/inspector/inspector';
import InspectorRating from './pages/rate-inspector/rating/rating';
import InspectorPreview from './pages/rate-inspector/preview/preview';
import BuildingLocation from './pages/rate-building/location/location';
import BuildingRating from './pages/rate-building/rating/rating';
import BuildingPreview from './pages/rate-building/preview/preview';
import Reviews from './pages/reviews/reviews';
import Search from "./pages/search/search";
import Completed from "./pages/completed/completed";
import InspectorDetail from "./pages/inspector-detail/inspector-detail";
import BuildingDeptDetail from './pages/building-dept-detail/inspector-detail';
import AdminUpload from './pages/admin/upload';

import styles from './app.module.scss';

function App() {
  return (
    <div className={styles.app}>
      <Route path="/" exact component={Landing}/>
      <Route path="/rate-inspector/location" exact component={InspectorLocation}/>
      <Route path="/rate-inspector/inspector" exact component={InspectorSelection} />
      <Route path="/rate-inspector/rating" exact component={InspectorRating} />
      <Route path="/rate-inspector/preview" exact component={InspectorPreview} />
      <Route path="/rate-building/location" exact component={BuildingLocation}/>
      <Route path="/rate-building/rating" exact component={BuildingRating} />
      <Route path="/rate-building/preview" exact component={BuildingPreview} />
      <Route path="/reviews" exact component={Reviews}/>
      <Route path="/search" exact component={Search}/>
      <Route path="/completed" exact component={Completed}/>
      <Route path="/inspector/:id" exact component={InspectorDetail}/>
      <Route path="/bldg-dept/:id" exact component={BuildingDeptDetail}/>
      <Route path="/admin" exact component={AdminUpload} />
    </div>
  );
}

export default App;
