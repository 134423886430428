import React, { Component } from 'react';
import firebase from 'firebase';

class FileUpload extends Component {
    constructor(props) {
        super(props);

        this.db = firebase.firestore();
        this.state = {
            fileTypes: ['.csv'],
            selectedFile: null,
            ...props
        };
    }

    onFileChange = e => {
        this.setState({
            selectedFile: e.target.files[0]
        });
    }

    uploadData(data) {
        debugger;
        if (this.state.dataType === 'location') {
            this.db.collection('locations').doc()
            // this.db.collection('locations').add({
            //     city: data[0]
            //     entity:
            // })
        }
        // else if (this.state.dataType === 'inspector') {

        // }
    }

    onUpload = async () => {
        const formData = new FormData();
        formData.append('file', this.state.selectedFile, this.state.selectedFile.name);

        // parse the file's contents
        const reader = new FileReader();
        reader.onload = e => {
            let rows = e.target.result.split('\n');
            let headers = rows.splice(0,1);
            headers = headers[0].split(',');

            rows.forEach(row => {
                // split up the row and send it to firebase
                let rowData = row.split(',').reduce((obj, item, i) => {
                    obj[headers[i]] = item;
                    return obj;
                }, {});
                this.uploadData(rowData);
            });
        };

        reader.readAsText(this.state.selectedFile);
    }

    render() {
        return (
            <div>
                <input
                    type='file'
                    accept='.csv'
                    onChange={ this.onFileChange }
                />
                <button disabled={!this.state.selectedFile} onClick={ this.onUpload }>Upload</button>
            </div>
        )
    }
};

export default FileUpload;
