import React, {useContext} from 'react';
import firebase from "firebase";

import InspectorContext from "../rate-inspector-context";
import Nav from "../../../components/nav/nav";
import RatingSteps from "../../../components/rating-steps/rating-steps";
import StarRating from "../../../components/star-rating/star-rating";
import NavBar from "../../../components/nav-bar/nav-bar";

import previewStyles from './preview.module.scss';
import buttonStyles from '../../../components/button/_button.module.scss';
import styles from "../rate-inspector.module.scss";

const Preview = () => {
  const inspector = useContext(InspectorContext);
  const db = firebase.firestore();

  function redirect() {
    if (inspector.name == null || inspector.location == null || inspector.headline == null || inspector.quote == null) {
      window.location = "/rate-inspector/location"
    }
  }

  redirect();

  function handleSubmit(event) {
    event.preventDefault();

    db.collection('ratings').add({
      inspectorId: inspector.id,
      blurb: inspector.quote,
      headline: inspector.headline,
      rating: inspector.rating,
      punctualRating: inspector.punctualRating,
      knowledgeableRating: inspector.knowledgeableRating,
      demeanorRating: inspector.demeanorRating
    })
      .then(function() {
        window.location = "/completed"
      })
      .catch(function(error) {
        console.error("Error adding inspector rating: ", error);
      });
  }

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Inspector Rating</h1>
          <RatingSteps type='inspector'/>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.centeredContent}>

            <div className={previewStyles.preview}>
              <div className={previewStyles.profile}>
                <div className={previewStyles.avatar}>
                  <img src="/images/content/inspector-coaster.svg" alt=""/>
                </div>
                <div>
                  <div className={previewStyles.info}>
                    <span>{inspector.name}</span>
                    <p>{inspector.position}</p>
                    <StarRating start={inspector.rating} disabled={true}/>
                  </div>
                  <div className={previewStyles.additionalInfo}>
                    <p>Punctual Rating: {inspector.punctualRating}</p>
                    <p>Knowledgeable Rating: {inspector.knowledgeableRating}</p>
                    <p>Demeanor Rating Rating: {inspector.demeanorRating}</p>
                  </div>
                </div>
              </div>

              <div className={previewStyles.review}>
                <h4>{inspector.headline}</h4>
                <p>{inspector.quote}</p>
                <button className={buttonStyles.primary} onClick={handleSubmit}>Submit Review</button>
              </div>
            </div>

          </div>
        </div>
      </section>

    </main>
  )
};

export default Preview;
