import React, {useState, useEffect} from 'react';
import firebase from "firebase";
import StarRatings from "react-star-ratings";
import { Link } from "react-router-dom";

import Nav from "../../components/nav/nav";
import NavBar from "../../components/nav-bar/nav-bar";
import Button from "../../components/button/button";
import Carousel from "../../components/carousel/carousel";

import detailStyles from './inspector-detail.module.scss';
import styles from '../rate-inspector/rate-inspector.module.scss';

const BuildingDeptDetail = (props) => {
  const db = firebase.firestore();
  let data = props.location.state.buildingDepartment;
  let [ratings, setRatings] = useState(null);
  let [ratingsLoaded, setRatingsLoaded] = useState(false);

  function getRatings() {
    db.collection('buildingDeptRatings')
      .where("locationId", "==", data.id)
      .get()
      .then((snapshot) => {
        let ratingArray = [];

        snapshot.docs.forEach(doc => {
          ratingArray.push(doc.data());
        });

        setRatings(ratingArray);
        setRatingsLoaded(true);
      });
  }

  function calculateRating(locationId, ratingType) {
    let fiveStarRatings = ratings.filter(function(arr){return arr[ratingType] === 5 && arr.locationId === locationId});
    let fourStarRatings = ratings.filter(function(arr){return arr[ratingType] === 4 && arr.locationId === locationId});
    let threeStarRatings = ratings.filter(function(arr){return arr[ratingType] === 3 && arr.locationId === locationId});
    let twoStarRatings = ratings.filter(function(arr){return arr[ratingType] === 2 && arr.locationId === locationId});
    let oneStarRatings = ratings.filter(function(arr){return arr[ratingType] === 1 && arr.locationId === locationId});

    return (5*fiveStarRatings.length + 4*fourStarRatings.length + 3*threeStarRatings.length + 2*twoStarRatings.length + 1*oneStarRatings.length) / (fiveStarRatings.length + fourStarRatings.length + threeStarRatings.length + twoStarRatings.length + oneStarRatings.length);
  }

  function renderAverageRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Average Rating</h2>
          {!isNaN(calculateRating(data.id, 'rating')) &&
          <strong>{calculateRating(data.id, 'rating').toFixed(2)}</strong>
          }

          {!isNaN(calculateRating(data.id, 'rating')) &&
          <StarRatings
            rating={calculateRating(data.id, 'rating')}
            starRatedColor="#FFCE02"
            numberOfStars={5}
            starDimension="22px"
            starSpacing="2px"
            name='rating'
          />
          }

          {isNaN(calculateRating(data.id, 'rating')) &&
          <p>This inspector has no rating yet.</p>
          }
        </section>
      )
    }
  }

  function renderWebsiteRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Website Score</h2>

          {!isNaN(calculateRating(data.id, 'websiteRating')) &&
          <>
            <strong>{calculateRating(data.id, 'websiteRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'websiteRating')) &&
          <p>This inspector has no website score yet.</p>
          }
        </section>
      )
    }
  }

  function renderPlanCheckRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Plan Check Score</h2>

          {!isNaN(calculateRating(data.id, 'planCheckRating')) &&
          <>
            <strong>{calculateRating(data.id, 'planCheckRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'planCheckRating')) &&
          <p>This inspector has no plan check score yet.</p>
          }
        </section>
      )
    }
  }

  function renderPermitProcessRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Permit Process Score</h2>

          {!isNaN(calculateRating(data.id, 'permitProcessRating')) &&
          <>
            <strong>{calculateRating(data.id, 'permitProcessRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'permitProcessRating')) &&
          <p>This inspector has no permit process score yet.</p>
          }
        </section>
      )
    }
  }

  function renderFeeRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Fee Score</h2>

          {!isNaN(calculateRating(data.id, 'feeRating')) &&
          <>
            <strong>{calculateRating(data.id, 'feeRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'feeRating')) &&
          <p>This inspector has no fee score yet.</p>
          }
        </section>
      )
    }
  }

  function renderStaffRating() {
    if(ratingsLoaded){
      if(!isNaN(calculateRating(data.id, 'staffRating'))) {

      }
      return (
        <section className={detailStyles.averageRating}>
          <h2>Staff Score</h2>

          {!isNaN(calculateRating(data.id, 'staffRating')) &&
          <>
            <strong>{calculateRating(data.id, 'staffRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'staffRating')) &&
          <p>This inspector has no staff score yet.</p>
          }
        </section>
      )
    }
  }

  function renderCarousel() {
    if(ratingsLoaded){
      return (
        <Carousel items={ratings} locationId={data.id}/>
      )
    }
  }

  useEffect(() => {
    getRatings();
  });

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.borderHeader}>
          <div className={styles.info}>
            <h1>{data.city} - Building Department</h1>
            <Link to={{pathname: '/rate-inspector/rating', state: {inspector: {name: data.name, id: data.id, location: data.location}}}}>
              <Button kind="secondary" label="Rate Inspector"/>
            </Link>
          </div>

          <div className={styles.location}>
            <strong>County:</strong> {data.county}
          </div>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={detailStyles.detailsContainer}>
              {renderAverageRating()}
              {renderWebsiteRating()}
              {renderPlanCheckRating()}
              {renderPermitProcessRating()}
              {renderFeeRating()}
              {renderStaffRating()}
            </div>

            <div className={detailStyles.carousel}>
              <h2>{data.city} Building Department Reviews</h2>
              {renderCarousel()}
            </div>
          </div>
        </div>
      </section>
    </main>
  )
};

export default BuildingDeptDetail;
