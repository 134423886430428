import React, { useState, useEffect } from "react";
import styles from "./_review-toolbar.module.scss";

const ReviewToolBar = (props) => {
  let [
    filterByLocation,
    // setFilterBy
  ] = useState('');

  // function handleChange(event){
  //   setFilterBy(event.target.value);
  // }

  function dispatch(val) {
    props.location(val);
  }

  useEffect(() => {
    dispatch(filterByLocation);
  }, [filterByLocation]); // eslint-disable-line

  return (
    <div className={styles.reviewToolBar}>
      <ul className={styles.options}>
        <li className={styles.records}>Displaying {props.records} records</li>

        {/*<li className={styles.filters}>*/}
        {/*  <div className={styles.filterContainer}>*/}
        {/*    <em className={props.locationResult ? styles.tooltip : styles.tooltip + ' ' + styles.toolTipDisabled}>*/}
        {/*      Sorry no Inspectors in that location*/}
        {/*    </em>*/}
        {/*    <input className={styles.filterInput} type="text" value={filterByLocation} placeholder="Filter by city name" onChange={handleChange}/>*/}
        {/*  </div>*/}
        {/*</li>*/}
      </ul>
    </div>
  )
};

export default ReviewToolBar;
