import React, {useState, useContext, useEffect} from 'react';
import styles from "../rate-inspector.module.scss";
import Nav from "../../../components/nav/nav";
import RatingSteps from "../../../components/rating-steps/rating-steps";
import Button from "../../../components/button/button";
import Select from "../../../components/select/select";
import InspectorContext from "../rate-inspector-context";
import NavBar from "../../../components/nav-bar/nav-bar";
import firebase from "firebase/app";

const Location = function() {
  const db = firebase.firestore();
  const inspector = useContext(InspectorContext);
  let [disableBtn, setDisableBtn] = useState(true);
  let [inspectorsLoaded, setInspectorsLoaded] = useState(false);

  function getData() {
    let defaultArray = [{ val: 'Please choose a location', disabled: true }];
    let dataArray = [];

    // fetch locations from firebase
    if(typeof inspector.locations === 'undefined') {
      db.collection('locations')
        .get()
        .then((snapshot) => {
          dataArray = snapshot.docs.map(doc => (
              {
                val: `${doc.data().city}, ${doc.data().state}`,
                disabled: false
              }
            )
          );
          dataArray.sort((a,b) => a.val > b.val ? 1 : -1);
          inspector.locations = defaultArray.concat(dataArray);
          setInspectorsLoaded(true);
        });
    }

    // don't fetch just display
    if(typeof inspector.locations !== 'undefined') {
      setInspectorsLoaded(true);
    }
  }

  function renderSelect() {
    if(inspectorsLoaded){
      return (
        <Select selectedOption={getSelectedOption} selected={inspector.location} options={inspector.locations}/>
      )
    }
    else return false;
  }

  function getSelectedOption(childData) {
    if(typeof childData.val !== 'undefined'){
      inspector.location = childData.val;
    }

    if(inspector.location !== 'Please choose a location' && typeof inspector.location !== 'undefined') {
      setDisableBtn(false);
    }
  }

  useEffect(() => {
    getData()
  });

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.header}>
          <h1>Inspector Rating</h1>
          <RatingSteps type='inspector'/>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.centeredContent}>
            <img src="/images/content/location.svg" alt=""/>
            {renderSelect()}
            <Button disabled={disableBtn} label="Next" kind="primary" alignment="center" to="/rate-inspector/inspector"/>
          </div>
        </div>
      </section>
    </main>
  )
};

export default Location;
