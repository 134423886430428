import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
import styles from '../rate-inspector/rate-inspector.module.scss';
import detailStyles from './inspector-detail.module.scss';
import Nav from "../../components/nav/nav";
import NavBar from "../../components/nav-bar/nav-bar";
import Button from "../../components/button/button";
import firebase from "firebase";
// import { getAllRatings } from '../../services/rating.service';
import StarRatings from "react-star-ratings";
import Carousel from "../../components/carousel/carousel";


const InspectorDetail = (props) => {
  const db = firebase.firestore();
  let [data, /*setData */] = useState(props.location.state.inspector);
  let [ratings, setRatings] = useState(null);
  let [ratingsLoaded, setRatingsLoaded] = useState(false);

  function getRatings() {
    db.collection('ratings')
      .where("inspectorId", "==", data.id)
      .get()
      .then((snapshot) => {
        let ratingArray = [];

        snapshot.docs.forEach(doc => {
          ratingArray.push(doc.data());
        });

        setRatings(ratingArray);
        setRatingsLoaded(true);
      })
  }

  function calculateRating(inspectorId, ratingType) {
    let fiveStarRatings = ratings.filter(function(arr){return arr[ratingType] === 5 && arr.inspectorId === inspectorId});
    let fourStarRatings = ratings.filter(function(arr){return arr[ratingType] === 4 && arr.inspectorId === inspectorId});
    let threeStarRatings = ratings.filter(function(arr){return arr[ratingType] === 3 && arr.inspectorId === inspectorId});
    let twoStarRatings = ratings.filter(function(arr){return arr[ratingType] === 2 && arr.inspectorId === inspectorId});
    let oneStarRatings = ratings.filter(function(arr){return arr[ratingType] === 1 && arr.inspectorId === inspectorId});

    return (5*fiveStarRatings.length + 4*fourStarRatings.length + 3*threeStarRatings.length + 2*twoStarRatings.length + 1*oneStarRatings.length) / (fiveStarRatings.length + fourStarRatings.length + threeStarRatings.length + twoStarRatings.length + oneStarRatings.length);
  }

  function renderAverageRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Average Rating</h2>
          {!isNaN(calculateRating(data.id, 'rating')) &&
          <strong>{calculateRating(data.id, 'rating').toFixed(2)}</strong>
          }

          {!isNaN(calculateRating(data.id, 'rating')) &&
          <StarRatings
            rating={calculateRating(data.id, 'rating')}
            starRatedColor="#FFCE02"
            numberOfStars={5}
            starDimension="22px"
            starSpacing="2px"
            name='rating'
          />
          }

          {isNaN(calculateRating(data.id, 'rating')) &&
          <p>This inspector has no rating yet.</p>
          }
        </section>
      )
    }
  }

  function renderPunctualRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Punctual Score</h2>


          {!isNaN(calculateRating(data.id, 'punctualRating')) &&
          <>
            <strong>{calculateRating(data.id, 'punctualRating').toFixed(2)}</strong>
            <small>Completes project/work at the agreed upon or proper time.</small>
          </>
          }


          {isNaN(calculateRating(data.id, 'punctualRating')) &&
          <p>This inspector has no rating yet.</p>
          }
        </section>
      )
    }
  }

  function renderKnowledgeableRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Knowledgeable Score</h2>

          {!isNaN(calculateRating(data.id, 'knowledgeableRating')) &&
          <>
            <strong>{calculateRating(data.id, 'knowledgeableRating').toFixed(2)}</strong>
            <small>Knows the codes and construction.</small>
          </>
          }

          {isNaN(calculateRating(data.id, 'knowledgeableRating')) &&
          <p>This inspector has no rating yet.</p>
          }
        </section>
      )
    }
  }

  function renderDemeanorRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Demeanor Score</h2>

          {!isNaN(calculateRating(data.id, 'demeanorRating')) &&
          <>
            <strong>{calculateRating(data.id, 'demeanorRating').toFixed(2)}</strong>
            <small>Great to work with or God complex/ power mongrel.</small>
          </>
          }

          {isNaN(calculateRating(data.id, 'demeanorRating')) &&
          <p>This inspector has no rating yet.</p>
          }
        </section>
      )
    }
  }

  function renderWebsiteRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Website Score</h2>

          {!isNaN(calculateRating(data.id, 'websiteRating')) &&
          <>
            <strong>{calculateRating(data.id, 'websiteRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'websiteRating')) &&
          <p>This inspector has no website score yet.</p>
          }
        </section>
      )
    }
  }

  function renderPlanCheckRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Plan Check Score</h2>

          {!isNaN(calculateRating(data.id, 'planCheckRating')) &&
          <>
            <strong>{calculateRating(data.id, 'planCheckRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'planCheckRating')) &&
          <p>This inspector has no plan check score yet.</p>
          }
        </section>
      )
    }
  }

  function renderPermitProcessRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Permit Process Score</h2>

          {!isNaN(calculateRating(data.id, 'permitProcessRating')) &&
          <>
            <strong>{calculateRating(data.id, 'permitProcessRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'permitProcessRating')) &&
          <p>This inspector has no permit process score yet.</p>
          }
        </section>
      )
    }
  }

  function renderFeeRating() {
    if(ratingsLoaded){
      return (
        <section className={detailStyles.averageRating}>
          <h2>Fee Score</h2>

          {!isNaN(calculateRating(data.id, 'feeRating')) &&
          <>
            <strong>{calculateRating(data.id, 'feeRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'feeRating')) &&
          <p>This inspector has no fee score yet.</p>
          }
        </section>
      )
    }
  }

  function renderStaffRating() {
    if(ratingsLoaded){
      if(!isNaN(calculateRating(data.id, 'staffRating'))) {

      }
      return (
        <section className={detailStyles.averageRating}>
          <h2>Staff Score</h2>

          {!isNaN(calculateRating(data.id, 'staffRating')) &&
          <>
            <strong>{calculateRating(data.id, 'staffRating').toFixed(2)}</strong>
          </>
          }

          {isNaN(calculateRating(data.id, 'staffRating')) &&
          <p>This inspector has no staff score yet.</p>
          }
        </section>
      )
    }
  }

  function renderCarousel() {
    if(ratingsLoaded){
      return (
        <Carousel items={ratings} inspectorId={data.id}/>
      )
    }
  }

  useEffect(() => {
    getRatings();
  });

  return (
    <main className={styles.splitView}>
      <Nav/>
      <NavBar/>

      <section className={styles.container}>
        <header className={styles.borderHeader}>
          <div className={styles.info}>
            <h1>{data.name}</h1>
            <Link to={{pathname: '/rate-inspector/rating', state: {inspector: {name: data.name, id: data.id, location: data.location}}}}>
              <Button kind="secondary" label="Rate Inspector"/>
            </Link>
          </div>

          <div className={styles.location}>
            <strong>City:</strong> {data.location.city} <strong>State:</strong> {data.location.state} <strong>County:</strong> {data.location.county}
          </div>
        </header>

        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={detailStyles.detailsContainer}>
              {renderAverageRating()}

              {renderPunctualRating()}

              {renderKnowledgeableRating()}

              {renderDemeanorRating()}

              {renderWebsiteRating()}

              {renderPlanCheckRating()}

              {renderPermitProcessRating()}

              {renderFeeRating()}

              {renderStaffRating()}
            </div>

            <div className={detailStyles.carousel}>
              <h2>What Others Are Saying About {data.name}</h2>
              {renderCarousel()}
            </div>
          </div>
        </div>
      </section>
    </main>
  )
};

export default InspectorDetail;
