import React from 'react';
import { useHistory } from "react-router-dom";
import styles from './_button.module.scss';

const Button = (props) => {
  let history = useHistory();

  function handleClick() {
    history.push(props.to);

    if(props.state){
      history.push({
        state: props.state
      })
    }
  }

  return (
    <button
      disabled={props.disabled}
      className={props.kind === 'primary' ? styles.primary + `${props.alignment === 'center' ? ' ' + styles.centered : ''}` : styles.secondary + `${props.alignment === 'center' ? ' ' + styles.centered : ''}`}
      onClick={handleClick}>
      {props.label}
    </button>
  )
};

export default Button;
