import React from 'react';
import styles from './landing.module.scss';
import Button from '../../components/button/button';
import Footer from '../../components/footer/footer';

const Landing = () => {
  return (
    <section className={styles.landing}>
      <div className={styles.landingContainer}>
        <img src="/images/branding/rmi-logo.svg" alt=""/>
        <h3>Reliable, simple & modern rating system!</h3>
        <Button label="Get Started" kind="primary" alignment="center" to="/rate-inspector/location"/>
      </div>

      <Footer kind="docked"/>
    </section>
  )
};

export default Landing;
