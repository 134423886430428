import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './_footer.module.scss';
import Modal from '../modal/modal';
import PrivacyPolicy from "../privacy-policy/privacy-policy";
import TermsOfService from "../terms-of-service/terms-of-service";

const Footer = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState();

  function displayModal(data) {
    setModalContent(data);
    setShowModal(true);
  }

  function dismissModal() {
    setShowModal(false);
  }

  return (
    <footer className={styles.footer + `${props.kind === 'docked' ? ' ' + styles.docked : ''}`}>
      <Link to="" onClick={() => displayModal(TermsOfService)}>Terms of Service</Link>
      <Link to="" onClick={() => displayModal(PrivacyPolicy)}>Privacy Policy</Link>

      <a href="mailto:stewartmanning@gmail.com?subject=Rate My Inspector">Contact Us</a>
      <p>© 2020 RMI All Rights Reserved.</p>

      <Modal show={showModal} handleClose={dismissModal} view={modalContent}/>
    </footer>
  )
};

export default Footer;
