import React from 'react';
import ReactDOM from 'react-dom';
import styles from "./off-canvas.module.scss";

const OffCanvas = (props) => {
  if(!props.show) {
    return null;
  }

  return (
    ReactDOM.createPortal(
    <div className={styles.offCanvas}>
      <img className={styles.branding} src="/images/branding/rmi-icon.svg" alt=""/>
      <img className={styles.close} src="/images/ui/close.svg" alt="" onClick={props.handleClose}/>

      <ul className={styles.menu}>
        <a href="/rate-inspector/location">
          <li>
            <img src="/images/content/clipboards.svg" alt=""/>
            <span>Rate Inspector</span>
          </li>
        </a>

        <a href="/rate-building/location">
          <li>
            <img src="/images/content/building.svg" alt=""/>
            <span>Rate Building Department</span>
          </li>
        </a>

        <a href="/reviews">
          <li>
            <img src="/images/content/reviews.svg" alt=""/>
            <span>Reviews</span>
          </li>
        </a>
      </ul>
    </div>,
      document.getElementById('off-canvas-root')
    )
  )
};

export default OffCanvas;
