import React from 'react';
import Slider from "react-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import styles from './carousel.module.scss';

const Carousel = (props) => {
  let settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  const carouselItems = props.items.map((item) => {
    return (
      <div className={styles.slide} key={item.inspectorId}>
        <h3>{item.headline}</h3>
        <p>{item.blurb}</p>
      </div>
    )
  });

  return (
    <div className={styles.carousel}>
      <div className={styles.container}>
        {props.items.length > 0 &&
          <Slider {...settings}>
            {carouselItems}
          </Slider>
        }
        </div>
        {props.items.length === 0 &&
          <p>This inspector has no reviews yet.</p>
        }
    </div>
  )
};

export default Carousel;
