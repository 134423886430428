import React from 'react';
import styles from "./search.module.scss";
import Nav from "../../components/nav/nav";

const Search = () => {
    return (
      <main className={styles.splitView}>
        <Nav/>

        <section className={styles.content}>
          <h1>Search</h1>
        </section>
      </main>
    )
};

export default Search;
